#header {
  background-image: url(../../../public/Images/bg12.png);
  height: 870px; // 485px
  max-width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;

  @media only screen and (max-width: 1399px) {
    height: 850px;
  }

  @media only screen and (max-width: 1199px) {
    height: 850px;
  }

  @media only screen and (max-width: 991px) {
    height: 850px;
  }

  @media only screen and (max-width: 767px) {
    height: 780px;
    background-image: url(../../../public/Images/bg12.png);
    background-size: cover;
  }

  @media only screen and (max-width: 425px) {
    height: 850px;
  }

  .start {
    z-index: 100;
  }

  .lite {
    position: absolute;
    top: -10px;
    left: 53%;
    &:lang(ar) {
      display: none;
    }

    @media only screen and (max-width: 1399px) {
      top: -45px;
      left: 52%;
    }

    @media only screen and (max-width: 1199px) {
      top: -50px;
      left: 50%;
    }

    @media only screen and (max-width: 991px) {
      top: -90px;
      left: 47%;
    }

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  .navbar {
    z-index: 10;
    @media screen and (max-width: 991px) {
      .navbar-collapse {
        position: absolute;
        top: 50px;
        width: 155px;
        z-index: 10;
        color: #ffffff;

        .nav-link {
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
      #basic-navbar-nav {
        background-color: #ffffff;
        border: 1px solid #ccc;
        color: #ffffff;
        z-index: 10;
      }
    }
  }

  .header-navigation {
    .navbar-nav {
      a {
        font-size: 18px;
        font-weight: 700;
        color: rgb(255, 255, 255);
        z-index: 10;

        @media only screen and (max-width: 991px) {
          color: black;
        }
      }
    }
  }

  .contact {
    z-index: 10;
    // &:lang(ar){
    //   direction: ltr;
    // }
  }

  .lang {
    z-index: 100;
    text-align: center;
  }

  .tell {
    &:lang(ar) {
      float: right;
    }
  }
  .langButton {
    &:lang(ar) {
      float: left;
    }
  }

  .header-download-btns-container {
    // third item not in flex
    gap: 20px;

    :nth-child(3n) {
      width: 100%;
      height: 0;
    }

    :nth-child(4n) {
      margin-top: -20px !important;
    }
  }

  .header-download-btn {
    display: inline-flex;
    align-items: center;
    gap: 20px;
    border-radius: 15px;
    color: #000;
    font-size: 14px;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    padding: 8px 20px;
    width: 190px;

    &-subtitle {
      font-size: 12px;
    }

    &-title {
      font-size: 18px;
    }

    img {
      width: 25px;
    }
  }

  .header-download-btn:hover {
    filter: brightness(0.9);
  }
  .header-download-btn:active {
    filter: brightness(0.8);
  }
}

#header.privacy {
  @media only screen and (min-width: 1400px) {
    height: 180px;
  }
  @media only screen and (max-width: 1399px) {
    height: 180px;
  }

  @media only screen and (max-width: 1199px) {
    height: 150px;
  }

  @media only screen and (max-width: 991px) {
    height: 150px;
  }

  @media only screen and (max-width: 767px) {
    height: 115px;
    background-image: url(../../../public/Images/bg12.png);
    background-size: cover;
  }

}
