@import "assets/styles/variables";
#root {
  scroll-behavior: smooth !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  &:lang(ar) {
    padding-right: 0.75rem;
    padding-left: calc(1.5em + 0.75rem) !important;
    background-position: left calc(0.375em + 0.1875rem) center;
  }
}

#home-page {
  h4 {
    text-overflow: ellipsis;
    overflow: hidden;
    // height: 140px;

    @media only screen and (max-width: 575px) {
      // height: 130px;
    }
  }

  .time {
    border: 1px solid black;
    width: 65%;
    height: 90%;
    border-radius: 50% 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 1399px) {
      height: 75%;
      width: 70%;
    }

    @media only screen and (max-width: 1199px) {
      height: 85%;
      width: 90%;
    }

    @media only screen and (max-width: 991px) {
      height: 80%;
      width: 110%;
    }

    @media only screen and (max-width: 767px) {
      height: 90%;
      width: 75%;
    }
    @media only screen and (max-width: 575px) {
      height: 95%;
      width: 95%;
    }

    @media only screen and (max-width: 425px) {
      height: 88%;
      width: 90%;
    }

    @media only screen and (max-width: 375px) {
      height: 85%;
      width: 100%;
    }

    @media only screen and (max-width: 320px) {
      height: 75%;
      width: 110%;
    }
  }

  .title {
    text-shadow: 0px -30px rgba(0, 0, 0, 0.05);
  }

  .clock {
    text-align: -webkit-center;
  }

  .under-count {
    position: relative;

    div {
      // z-index: 1;
      position: relative;
    }

    .img-under-count {
      position: absolute;
      left: -25%;
      top: -85%;
      z-index: 1;

      @media only screen and (max-width: 1440px) {
        left: -7%;
        top: -85%;
      }

      @media only screen and (max-width: 1399px) {
        left: -10%;
        top: -85%;
      }

      @media only screen and (max-width: 1199px) {
        left: -15%;
        top: -65%;
      }

      @media only screen and (max-width: 991px) {
        display: none;
      }
    }

    .img-under-count1 {
      position: absolute;
      left: -25%;
      bottom: -16%;
      z-index: 1;

      @media only screen and (max-width: 1440px) {
        left: -7%;
        bottom: -16%;
      }

      @media only screen and (max-width: 1399px) {
        left: -10%;
        bottom: -12%;
      }

      @media only screen and (max-width: 1199px) {
        left: -14%;
        bottom: -10%;
      }

      @media only screen and (max-width: 991px) {
        display: none;
      }
    }

    .img-under-count2 {
      position: absolute;
      right: -21%;
      bottom: -7%;
      z-index: 1;

      @media only screen and (max-width: 1440px) {
        right: -4%;
        bottom: -7%;
      }

      @media only screen and (max-width: 1399px) {
        right: -4%;
        bottom: -4%;
      }

      @media only screen and (max-width: 1199px) {
        right: -4%;
        bottom: -2%;
      }

      @media only screen and (max-width: 991px) {
        display: none;
      }
    }

    .img-under-count3 {
      position: absolute;
      left: 3%;
      top: 0%;
      z-index: 1;

      @media only screen and (max-width: 1440px) {
        left: -8%;
        top: 0%;
      }

      @media only screen and (max-width: 1399px) {
        left: -12%;
        top: 0%;
      }

      @media only screen and (max-width: 1199px) {
        left: -15%;
        top: -8%;
      }

      @media only screen and (max-width: 991px) {
        display: none;
      }
    }

    .img-under-count4 {
      position: absolute;
      right: 0%;
      top: -40%;
      z-index: 1;

      @media only screen and (max-width: 1440px) {
        right: 0%;
        top: -40%;
      }

      @media only screen and (max-width: 1399px) {
        right: 0%;
        top: -40%;
      }

      @media only screen and (max-width: 1199px) {
        right: 0%;
        top: -40%;
      }

      @media only screen and (max-width: 991px) {
        display: none;
      }
    }

    .img-under-count5 {
      position: absolute;
      left: 8%;
      top: 25%;
      z-index: 1;

      @media only screen and (max-width: 1440px) {
        left: -6%;
        top: 25%;
      }

      @media only screen and (max-width: 1399px) {
        left: -5%;
        top: 25%;
      }

      @media only screen and (max-width: 1199px) {
        left: -3%;
        top: 20%;
      }

      @media only screen and (max-width: 991px) {
        display: none;
      }
    }
  }

  .slider-content {
    text-align: -webkit-center;
    z-index: 1;
  }

  .card-img {
    z-index: 1000;
    @media only screen and (max-width: 767px) {
      display: block;
    }
  }

  .image {
    @media only screen and (max-width: 1399px) {
      width: max-content;
    }

    @media only screen and (max-width: 1199px) {
      width: max-content;
      margin: 0;
    }

    @media only screen and (max-width: 767px) {
    }

    @media only screen and (max-width: 425px) {
    }
  }

  .screens {
    background-image: url(../../../public/Images/screenBg.png);
    height: 100%;
    max-width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    @media only screen and (max-width: 575px) {
      background-size: cover;
    }
  }

  .title-screen {
    margin-top: 15%;

    h1 {
      color: white;
      &::before {
        content: attr(data-title);
        display: flex;
        color: rgb(255, 255, 255);
        opacity: 0.09;
        position: absolute;
        top: 17%;
        left: 34%;
        font-size: 55px;
      }
    }
    @media only screen and (max-width: 1440px) {
      h1 {
        &::before {
          top: 17%;
          left: 28%;
          font-size: 55px;
        }
      }
    }
    @media only screen and (max-width: 1199px) {
      h1 {
        &::before {
          top: 16%;
          left: 22%;
          font-size: 45px;
        }
      }
    }

    @media only screen and (max-width: 991px) {
      margin-top: 25%;
      h1 {
        &::before {
          top: 17%;
          left: 20%;
          font-size: 45px;
        }
      }
    }

    @media only screen and (max-width: 767px) {
      h1 {
        &::before {
          top: 16%;
          left: 16%;
          font-size: 40px;
        }
      }
    }

    @media only screen and (max-width: 575px) {
      margin-top: 33%;

      h1 {
        &::before {
          display: none;
        }
      }
    }
    @media only screen and (max-width: 425px) {
      margin-top: 35%;
      text-shadow: 0 0;
      h1 {
        font-size: 22px;
      }
    }
    @media only screen and (max-width: 375px) {
      margin-top: 37%;
      text-shadow: 0 0;
      h1 {
        font-size: 18px;
      }
    }
  }

  .swiper {
    user-select: none;
    width: 100%;
    height: 115%;
    overflow: visible;
    padding: 1% 0;

    @media only screen and (max-width: 575px) {
      width: 50%;
      padding: 8% 0;
    }
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: none;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-button-prev {
    color: black;
    top: 20px;
    background-image: url(../../../public/Images/arrow.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    transform: scale(-1, 1);
    &::after {
      display: none;
    }
    @media only screen and (max-width: 1199px) {
      top: 10px;
    }

    @media only screen and (max-width: 991px) {
      top: -10px;
      left: -10px;
    }
    @media only screen and (max-width: 575px) {
      top: -15px;
      left: -35px;
    }
    @media only screen and (max-width: 425px) {
      top: -15px;
      left: -25px;
    }
  }
  .swiper-button-next {
    color: black;
    top: 20px;
    background-image: url(../../../public/Images/arrow.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;

    &::after {
      display: none;
    }

    @media only screen and (max-width: 1199px) {
      top: 10px;
    }

    @media only screen and (max-width: 991px) {
      top: -10px;
      right: -10px;
    }
    @media only screen and (max-width: 575px) {
      top: -15px;
      right: -35px;
    }
    @media only screen and (max-width: 425px) {
      top: -15px;
      right: -25px;
    }
  }

  // download app

  .download {
    // downloadbg.png
    background-image: url(../../../public/Images/download2.png);
    height: 1000px;
    max-width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    display: flex;
    align-items: center;

    h1 {
      &::before {
        content: attr(data-title);
        display: flex;
        color: #000;
        opacity: 0.03;
        position: absolute;
        bottom: -50%;
        left: 38%;
        font-size: 48px;
      }
    }

    @media only screen and (max-width: 1399px) {
      height: 880px;
    }
    @media only screen and (max-width: 1199px) {
      height: 820px;
    }
    @media only screen and (max-width: 991px) {
      height: 650px;
    }
    @media only screen and (max-width: 767px) {
      height: 720px;
    }
    @media only screen and (max-width: 425px) {
      height: 600px;
    }

    .download-app-card {
      img {
        width: 32px;
      }
    }

    .download-content {
      // margin-top: 130px;

      a {
        :hover {
          color: rgb(66, 66, 66);
        }
      }

      @media only screen and (max-width: 1399px) {
        // margin-top: 130px;
      }
      @media only screen and (max-width: 1199px) {
        // margin-top: 40px;
      }
    }

    .image {
      display: inline-table;
      align-items: center;
      height: auto;
      width: 100%;
      z-index: 4;
      @media only screen and (max-width: 1199px) {
      }

      @media only screen and (max-width: 991px) {
      }

      @media only screen and (max-width: 767px) {
        display: none;
      }

      @media only screen and (max-width: 425px) {
        display: none;
      }

      @media only screen and (max-width: 375px) {
        display: none;
      }

      @media only screen and (max-width: 320px) {
        display: none;
      }
    }

    .download-details {
      .qr-div {
        @media only screen and (max-width: 425px) {
          &:lang(ar) {
            text-align: center;
          }
          &:lang(en) {
            text-align: center;
          }
        }
      }
      // margin-top: 100px;

      @media only screen and (max-width: 1399px) {
        h2 {
          font-size: 28px;
        }
      }

      @media only screen and (max-width: 1199px) {
        // margin-top: 120px;
        h2 {
          font-size: 23px;
        }
        h4 {
          font-size: 18px;
        }
      }

      @media only screen and (max-width: 991px) {
        h2 {
          font-size: 17px;
        }
        h1 {
          font-size: 30px;
        }
        .qrimg {
          width: 50%;
        }
        h4 {
          font-size: 14px;
        }
        h6 {
          font-size: 14px;
        }
      }

      @media only screen and (max-width: 767px) {
        // margin-top: 140px;
        h2 {
          font-size: 25px;
        }
        h1 {
          font-size: 40px;
        }
        .qrimg {
          width: 30%;
          justify-content: center;
        }
        h4 {
          font-size: 20px;
        }
        h6 {
          font-size: 16px;
        }
      }

      @media only screen and (max-width: 425px) {
        // margin-top: 35px;
        h2 {
          font-size: 20px;
        }
        h1 {
          font-size: 30px;
        }
        .qrimg {
          width: 30%;
          justify-content: center;
        }
        h4 {
          font-size: 20px;
          display: contents;
        }
        h6 {
          font-size: 16px;
        }
      }

      @media only screen and (max-width: 375px) {
        // margin-top: 35px;
        h2 {
          font-size: 20px;
        }
        h1 {
          font-size: 30px;
        }
        .qrimg {
          width: 30%;
          justify-content: center;
        }
        h4 {
          font-size: 16px;
          display: contents;
        }
        h6 {
          font-size: 11px;
        }
      }

      @media only screen and (max-width: 320px) {
      }

      @media only screen and (min-width: 992px) {
        .qrimg {
          width: 100%;
        }
      }
    }
  }

  // news

  .news {
    background-image: url(../../../public/Images/footerbg.png);
    height: 100%;
    max-width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 100px;
    position: relative;

    .news-letter {
      background-color: white;
      border-radius: 25px;
      height: 350px;
      padding-top: 20px;
      z-index: 100;

      @media only screen and (max-width: 1199px) {
        height: 350px;
        padding-top: 50px;

        img {
          width: 85%;
        }
      }
      @media only screen and (max-width: 1199px) {
        height: 300px;
        padding-top: 20px;

        img {
          width: 85%;
        }
      }
      @media only screen and (max-width: 991px) {
        height: 250px;
        padding-top: 20px;
      }
      @media only screen and (max-width: 767px) {
        height: 225px;
        padding-top: 30px;

        img {
          width: 85%;
        }
      }
      @media only screen and (max-width: 425px) {
        height: 210px;
        padding-top: 31px;

        img {
          width: 90%;
        }
      }
      @media only screen and (max-width: 375px) {
        height: 200px;
        padding-top: 30px;

        img {
          width: 90%;
        }
      }
    }

    .news-details {
      h1 {
        color: #000;

        &::before {
          content: attr(data-title);
          display: flex;
          color: #000;
          opacity: 0.03;
          position: absolute;
          top: -35%;
          left: -8%;
          font-size: 80px;
        }
        &:lang(ar) {
          &::before {
            right: -8%;
          }
        }

        @media only screen and (max-width: 1199px) {
          font-size: 30px;
          &::before {
            top: -35%;
            left: -8%;
            font-size: 60px;
          }
        }
        @media only screen and (max-width: 991px) {
          font-size: 25px;
          &::before {
            top: -35%;
            left: -8%;
            font-size: 45px;
          }
        }
        @media only screen and (max-width: 767px) {
          font-size: 20px;
          &::before {
            top: -12%;
            left: -8%;
            font-size: 29px;
          }
        }
        @media only screen and (max-width: 425px) {
          font-size: 15px;
          &::before {
            display: none;
          }
        }
        @media only screen and (max-width: 375px) {
          font-size: 13px;
          &::before {
            display: none;
          }
        }
      }

      h2 {
        @media only screen and (max-width: 1199px) {
          font-size: 25px;
        }

        @media only screen and (max-width: 991px) {
          font-size: 20px;
        }
        @media only screen and (max-width: 767px) {
          font-size: 14px;
        }
        @media only screen and (max-width: 425px) {
          font-size: 12px;
        }
        @media only screen and (max-width: 375px) {
          font-size: 11px;
        }
        @media only screen and (max-width: 320px) {
          font-size: 10px;
        }
      }

      .input-email {
        height: 60px;
        border-radius: 15px;
        background-color: theme-color("gray1");
        font-size: 20px;

        @media only screen and (max-width: 1199px) {
          height: 50px;
          font-size: 18px;
        }
        @media only screen and (max-width: 991px) {
          font-size: 16px;
          height: 40px;
        }
        @media only screen and (max-width: 767px) {
          font-size: 13px;
          height: 40px;
          width: 90%;
        }
        @media only screen and (max-width: 425px) {
          font-size: 12px;
        }
        @media only screen and (max-width: 375px) {
          font-size: 12px;
          width: 95%;
        }
      }

      .btn-email {
        height: 60px;
        width: 75%;
        border-radius: 15px;
        font-size: 22px;

        @media only screen and (max-width: 1199px) {
          height: 50px;
          font-size: 18px;
          width: 85%;
        }
        @media only screen and (max-width: 991px) {
          font-size: 14px;
          height: 40px;
          width: 90%;
        }
        @media only screen and (max-width: 767px) {
        }
        @media only screen and (max-width: 425px) {
        }
        @media only screen and (max-width: 375px) {
          font-size: 14px;
          width: 95%;
        }
      }
    }

    .footer-logo {
      text-align: center;
      margin-top: 80px;
      margin-bottom: 10px;
      img {
        // margin-top: 55px;
        width: 285px;
        height: 200px;

        @media only screen and (max-width: 1399px) {
          width: 200px;
          height: auto;
        }
        @media only screen and (max-width: 1199px) {
          width: 175px;
          height: auto;
        }
        @media only screen and (max-width: 991px) {
          width: 150px;
          height: auto;
        }
        @media only screen and (max-width: 767px) {
          width: 150px;
          height: 100%;
        }
        @media only screen and (max-width: 425px) {
          width: 100px;
          height: auto;
        }
      }

      .contact {
        display: flex;
        justify-content: space-between;
        margin-top: 80px;
        flex-wrap: wrap;

        h4 {
          display: contents;
          color: white;
          font-size: 20px;
          font-weight: 500;
        }

        .icons {
          color: theme-color("primary");
          font-size: 25px;
          margin-right: 15px;
        }

        @media only screen and (max-width: 1199px) {
          h4 {
            font-size: 18px;
          }
          .icons {
            font-size: 20px;
          }
        }

        @media only screen and (max-width: 991px) {
          h4 {
            font-size: 14px;
          }
          .icons {
            margin-right: 5px;
            font-size: 17px;
          }
        }

        @media only screen and (max-width: 767px) {
          gap: 10px;
          h4 {
            font-size: 15px;
          }
          .icons {
            margin-right: 8px;
            font-size: 20px;
          }
        }

        @media only screen and (max-width: 425px) {
          h4 {
            font-size: 14px;
          }
          .icons {
            margin-right: 5px;
            font-size: 20px;
          }
        }
      }
    }
  }
}
